<template>
  <component :is="type" class="title" :class="additionalClass">
    <template v-if="showBackButton">
      <IconBack class="icon-back" @click="goBackPage" />
    </template>
    <slot />
  </component>
</template>

<script setup lang="ts">
import IconBack from '@/assets/icons/icon-back.svg'

const router = useRouter()
const { localePath } = useLocale()

type Props = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  /** タイトル左のオレンジのバーの代わりに、戻るボタンを表示するか */
  showBackButton?: boolean
  backUrl?: string | undefined
}

const props = withDefaults(defineProps<Props>(), {
  type: 'h1',
  showBackButton: false,
  backUrl: undefined,
})

const additionalClass = computed(() => {
  return [`-type-${props.type}`, { '-back': props.showBackButton }]
})

const goBackPage = () => {
  // URLが指定されていない場合は一つ前のページに戻る
  props.backUrl ? navigateTo(localePath(props.backUrl)) : router.back()
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.title {
  display: flex;
  font-size: 24px;

  &::before {
    background: v.$orange;
    border-radius: 6px;
    content: '';
    display: block;
    margin-right: v.space(2);
    width: 5px;

    @include m.sp {
      width: 3px;
    }
  }

  &.-type-h1 {
    font-size: 20px;

    @include m.sp {
      font-size: 16px;
    }
  }

  &.-type-h3 {
    font-size: 20px;

    &::before {
      margin-right: 5px;
      width: 3px;
    }
    @include m.sp {
      font-size: 16px;
    }
  }

  &.-back {
    align-items: center;

    &::before {
      display: none;
    }
  }

  > .icon-back {
    background: v.$gray;
    border-radius: 50px;
    cursor: pointer;
    fill: v.$gray-5;
    height: 30px;
    margin-right: v.space(2);
    padding: 6px;
    width: 30px;
  }
}
</style>
